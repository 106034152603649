import(/* webpackMode: "eager", webpackExports: ["About"] */ "/home/runner/work/rjoydip.com/rjoydip.com/src/components/pages/about.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Educations"] */ "/home/runner/work/rjoydip.com/rjoydip.com/src/components/pages/education.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KeySkills"] */ "/home/runner/work/rjoydip.com/rjoydip.com/src/components/pages/keySkills.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Projects"] */ "/home/runner/work/rjoydip.com/rjoydip.com/src/components/pages/projects.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Skills"] */ "/home/runner/work/rjoydip.com/rjoydip.com/src/components/pages/skills.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Works"] */ "/home/runner/work/rjoydip.com/rjoydip.com/src/components/pages/works.tsx");
